import { defineStore } from "pinia";
import dayjs from "dayjs";

export const useNotifications = defineStore("notifications", () => {

    let notifications: Ref<Array<{value:string,type:'success'|'danger'|'warning', icon?:string, color?:string, time:number}>> = ref([])

    const push = (value:string, type:'success'|'danger'|'warning', ms:number = 5000) => {

        const notification:{value:string,type:'success'|'danger'|'warning', icon?:string, color?:string, time:number} = {value:value, type:type, time:dayjs().unix()}

        switch (type)
        {
            case 'success':
                notification.icon="fa-circle-check"
                notification.color="bg-green-500"
                break;
            case 'danger':
                notification.icon="fa-triangle-exclamation"
                notification.color="bg-red-500"
                break;
            case 'warning':
                notification.icon="fa-circle-exclamation"
                notification.color="bg-orange-500"
                break;
        }

        notifications.value.push(notification)

        setTimeout(()=>{
            notifications.value = notifications.value.filter(n=>{
                return n == notification
            })
        }, ms)
    }

    return {notifications, push};
})
